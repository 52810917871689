
import axios from "axios";
//React
import { React, useState, useEffect } from "react"
import { toast } from "react-toastify"
import { useInView } from "react-cool-inview"
import { useAuthContext } from "../../../Hooks/useAuthContext"
import { useNavigate, useParams } from "react-router-dom";
//Icons
import { faAdd } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"

//Components
import MainContainer from "../../../Components/Containers/MainContainer"
import SideContainer from "../../../Components/Containers/SideContainer"

//Components
import FormCheck from "../../../Components/Forms/FormCheck"
import FormControl from "../../../Components/Forms/FormControl"
//Bootstrap




//Styles
import "./Dashboard.css"
import TimesheetDay from "../Components/TimesheetDay"
import ExpensesTable from "../Components/ExpensesTable"


export default function Dashboard({ setRefresh }) {

    const params = useParams();
    const navigate = useNavigate()
    const { user, dispatch } = useAuthContext();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState();
    const [hoursDifference, setHoursDifference] = useState(0);
    
    const [weekCommencing, setWeekCommencing] = useState(startOfWeek(new Date()));
    const [weekCommencing2, setWeekCommencing2] = useState();
    Date.prototype.addDays = function (days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
    }

    const [mondayEntries, setMondayEntries] = useState([]);
    const [tuesdayEntries, setTuesdayEntries] = useState([]);
    const [wednesdayEntries, setWednesdayEntries] = useState([]);
    const [thursdayEntries, setThursdayEntries] = useState([]);
    const [fridayEntries, setFridayEntries] = useState([]);
    const [weekendEntries, setWeekendEntries] = useState([]);

    const [expensesEntries, setExpensesEntries] = useState([]);
    


    const [timesheetCodes, setTimesheetCodes] = useState([]);
    const [nominalCodes, setNominalCodes] = useState([]);
    const [costCodes, setCostCodes] = useState([]);
    const [departmentCodes, setDepartmentCodes] = useState([]);
   


    const [submitting, setSubmitting] = useState(false);


    const [totalHours, setTotalHours] = useState(0);
    const [totalMiles, setTotalMiles] = useState(0);
    const [totalMileageExpenses, setMileageExpenses] = useState(0);
    const [totalExpenses, setTotalExpenses] = useState(0);
    const [totalByTimesheetCode, setTotalByTimesheetCode] = useState([]);


    const handleChangeDate = (e) => {
        console.log(e.target.value)
        var date = new Date(e.target.value)
        console.log(date)
        if (!isNaN(date) && date.getFullYear() > 2020) {
            console.log("date valid")
            setWeekCommencing(date);
            setWeekCommencing2(date.toLocaleDateString("en-GB"));
        }
        else {
            console.log("invalid date")
        }
        console.log(e.target.value)
        
    };

    // Load timesheet / project and nominal codes
    useEffect(() => {
        axios.get(`https://${process.env.REACT_APP_IP}/api/timesheetcodes/DepartmentCode?active=true`,
            {
                headers: { Authorization: `bearer ${user.token}` },
            }
        ).then((response) => {
            setDepartmentCodes(response.data)
        });
        axios.get(`https://${process.env.REACT_APP_IP}/api/timesheetcodes/CostCode?active=true`,
            {
                headers: { Authorization: `bearer ${user.token}` },
            }
        ).then((response) => {
            setCostCodes(response.data)
        });
        axios.get(`https://${process.env.REACT_APP_IP}/api/timesheetcodes/NominalCode?active=true`,
            {
                headers: { Authorization: `bearer ${user.token}` },
            }
        ).then((response) => {
            setNominalCodes(response.data)
        });
        axios.get(`https://${process.env.REACT_APP_IP}/api/timesheetcodes/TimesheetCode?active=true`,
            {
                headers: { Authorization: `bearer ${user.token}` },
            }
        ).then((response) => {
            setTimesheetCodes(response.data)
        });

        axios.get(`https://${process.env.REACT_APP_IP}/api/user/${user.id}`,
            {
                headers: { Authorization: `bearer ${user.token}` },
            }
        )
            .then((response) => {
                setCurrentUser(response.data)
            });

        axios.get(`https://${process.env.REACT_APP_IP}/api/timesheets/${user.id}/stats`,
            {
                headers: { Authorization: `bearer ${user.token}` },
            }
        )
            .then((response) => {
                setHoursDifference(response.data.hoursDifference)
            });

    }, []);

    // count up the totals
    useEffect(() => {

        var countHours = 0;
        var countMiles = 0;
        var countExpenses = 0;

        mondayEntries.forEach((entry) => {
            countHours += parseFloat(entry.hours);
            countMiles += parseFloat(entry.miles);
            countExpenses += parseFloat(entry.expenses);
        });
        tuesdayEntries.forEach((entry) => {
            countHours += parseFloat(entry.hours);
            countMiles += parseFloat(entry.miles);
            countExpenses += parseFloat(entry.expenses);
        });
        wednesdayEntries.forEach((entry) => {
            countHours += parseFloat(entry.hours);
            countMiles += parseFloat(entry.miles);
            countExpenses += parseFloat(entry.expenses);
        });
        thursdayEntries.forEach((entry) => {
            countHours += parseFloat(entry.hours);
            countMiles += parseFloat(entry.miles);
            countExpenses += parseFloat(entry.expenses);
        });
        fridayEntries.forEach((entry) => {
            countHours += parseFloat(entry.hours);
            countMiles += parseFloat(entry.miles);
            countExpenses += parseFloat(entry.expenses);
        });
        weekendEntries.forEach((entry) => {
            countHours += parseFloat(entry.hours);
            countMiles += parseFloat(entry.miles);
            countExpenses += parseFloat(entry.expenses);
        });
        setTotalHours(countHours);
        setTotalMiles(countMiles);
        setMileageExpenses(countMiles * 0.45);
        setTotalExpenses(countExpenses);

    }, [mondayEntries, tuesdayEntries, wednesdayEntries, thursdayEntries, fridayEntries, weekendEntries])

    // load timesheet
    useEffect(() => {
        console.log("Loading timesheet " + params.id);
        const getTimesheet = async () => {
            try {
                const res = await axios.get(
                    `https://${process.env.REACT_APP_IP}/api/timesheets/${params.id}`,
                    {
                        headers: { Authorization: `bearer ${user.token}` },
                    }
                ).then((response) => {
                    console.log("Timesheet loaded");

                    let mondayArr = [];

                    response.data.monday.map((entry) => {
                        mondayArr.push({ id: entry.id, date: entry.date, timesheetCode: entry.timesheetCode, task: entry.task, hours: entry.hours, miles: entry.miles, expenses: entry.expenses, interactionId: entry.interactionId, enquiryId: entry.enquiryId
                    });
                        timesheetCodes.push({ code: entry.timesheetCode, hours: entry.hours });
                    });
                    mondayArr.push({ date: weekCommencing.toISOString().split('T')[0], timesheetCode: '', task: '', hours: 0, miles: 0, expenses: 0 }); // add a blank line at the end
                    setMondayEntries(mondayArr);

                    let tuesdayArr = [];
                    response.data.tuesday.map((entry) => {
                        tuesdayArr.push({ id: entry.id, date: entry.date, timesheetCode: entry.timesheetCode, task: entry.task, hours: entry.hours, miles: entry.miles, expenses: entry.expenses, interactionId: entry.interactionId, enquiryId: entry.enquiryId
                    });
                    });
                    tuesdayArr.push({ date: weekCommencing.toISOString().split('T')[0], timesheetCode: '', task: '', hours: 0, miles: 0, expenses: 0 }); // add a blank line at the end
                    setTuesdayEntries(tuesdayArr);

                    let wednesdayArr = [];
                    response.data.wednesday.map((entry) => {
                        wednesdayArr.push({ id: entry.id, date: entry.date, timesheetCode: entry.timesheetCode, task: entry.task, hours: entry.hours, miles: entry.miles, expenses: entry.expenses, interactionId: entry.interactionId, enquiryId: entry.enquiryId
                    });
                        timesheetCodes.push({ code: entry.timesheetCode, hours: entry.hours });
                    });
                    wednesdayArr.push({ date: weekCommencing.toISOString().split('T')[0], timesheetCode: '', task: '', hours: 0, miles: 0, expenses: 0 }); // add a blank line at the end
                    setWednesdayEntries(wednesdayArr);

                    let thursdayArr = [];
                    response.data.thursday.map((entry) => {
                        thursdayArr.push({ id: entry.id, date: entry.date, timesheetCode: entry.timesheetCode, task: entry.task, hours: entry.hours, miles: entry.miles, expenses: entry.expenses, interactionId: entry.interactionId, enquiryId: entry.enquiryId
                    });
                        timesheetCodes.push({ code: entry.timesheetCode, hours: entry.hours });
                    });
                    thursdayArr.push({ date: weekCommencing.toISOString().split('T')[0], timesheetCode: '', task: '', hours: 0, miles: 0, expenses: 0 }); // add a blank line at the end
                    setThursdayEntries(thursdayArr);

                    let fridayArr = [];
                    response.data.friday.map((entry) => {
                        fridayArr.push({ id: entry.id, date: entry.date, timesheetCode: entry.timesheetCode, task: entry.task, hours: entry.hours, miles: entry.miles, expenses: entry.expenses, interactionId: entry.interactionId, enquiryId: entry.enquiryId
                    });
                        timesheetCodes.push({ code: entry.timesheetCode, hours: entry.hours });
                    });
                    fridayArr.push({ date: weekCommencing.toISOString().split('T')[0], timesheetCode: '', task: '', hours: 0, miles: 0, expenses: 0 }); // add a blank line at the end
                    setFridayEntries(fridayArr);


                    let weekendArr = [];
                    response.data.weekend.map((entry) => {
                        weekendArr.push({ id: entry.id, date: entry.date, timesheetCode: entry.timesheetCode, task: entry.task, hours: entry.hours, miles: entry.miles, expenses: entry.expenses, interactionId: entry.interactionId, enquiryId: entry.enquiryId 
                    });
                        timesheetCodes.push({ code: entry.timesheetCode, hours: entry.hours });
                    });
                    weekendArr.push({ date: weekCommencing.toISOString().split('T')[0], timesheetCode: '', task: '', hours: 0, miles: 0, expenses: 0 }); // add a blank line at the end
                    setWeekendEntries(weekendArr);

                    let expensesArr = [];
                    response.data.expenses.map((entry) => { expensesArr.push({ id: entry.id, nominalCode: entry.nominalCode, projectCode: entry.projectCode, departmentCode: entry.departmentCode, costCode: entry.costCode, pounds: entry.pounds, pence: entry.pence }); });
                    expensesArr.push({ nominalCode: '', projectCode: '', costCode: '', departmentCode: '', pounds: 0, pence: 0 }); // add a blank line at the end
                    setExpensesEntries(expensesArr);

                    console.log("returned date: ", response.data.weekCommencing)
                    setWeekCommencing(new Date(response.data.weekCommencing.split('T')[0]));
                    setWeekCommencing2(new Date(response.data.weekCommencing.split('T')[0]).toISOString("en-GB").split('T')[0]);
                    console.log("setting date: ", new Date(response.data.weekCommencing.split('T')[0]).toISOString("en-GB").split('T')[0]);
                    
                    setLoading(false);
                });

                console.log("totalByTimesheetCodes", totalByTimesheetCode)


            } catch (error) {
                if (error.response.status === 401) {
                    dispatch({ type: "LOGOUT" });
                    navigate("../../expired");
                } else {
                    setError(error.response.status);
                }
            } finally {
                setLoading(false);
            }
        };

        getTimesheet();
    }, [params.id, dispatch, user, navigate]);

    useEffect(() => {
        let timesheetCodes = [];

        mondayEntries.map((entry) => {
            if (entry.timesheetCode != "") {
                var existing = timesheetCodes.find((x) => x.code == entry.timesheetCode);
                if (existing != undefined)
                    existing.hours += parseFloat(entry.hours);
                else
                    timesheetCodes.push({ code: entry.timesheetCode, hours: parseFloat(entry.hours) });
            }
        });
        tuesdayEntries.map((entry) => {
            if (entry.timesheetCode != "") {
                var existing = timesheetCodes.find((x) => x.code == entry.timesheetCode);
                if (existing != undefined)
                    existing.hours += parseFloat(entry.hours);
                else
                    timesheetCodes.push({ code: entry.timesheetCode, hours: parseFloat(entry.hours) });
            }
        });
        wednesdayEntries.map((entry) => {
            if (entry.timesheetCode != "") {
                var existing = timesheetCodes.find((x) => x.code == entry.timesheetCode);
                if (existing != undefined)
                    existing.hours += parseFloat(entry.hours);
                else
                    timesheetCodes.push({ code: entry.timesheetCode, hours: parseFloat(entry.hours) });
            }
        });
        thursdayEntries.map((entry) => {
            if (entry.timesheetCode != "") {
                var existing = timesheetCodes.find((x) => x.code == entry.timesheetCode);
                if (existing != undefined)
                    existing.hours += parseFloat(entry.hours);
                else
                    timesheetCodes.push({ code: entry.timesheetCode, hours: parseFloat(entry.hours) });
            }
        });
        fridayEntries.map((entry) => {
            if (entry.timesheetCode != "") {
                var existing = timesheetCodes.find((x) => x.code == entry.timesheetCode);
                if (existing != undefined)
                    existing.hours += parseFloat(entry.hours);
                else
                    timesheetCodes.push({ code: entry.timesheetCode, hours: parseFloat(entry.hours) });
            }
        });
        weekendEntries.map((entry) => {
            if (entry.timesheetCode != "") {
                var existing = timesheetCodes.find((x) => x.code == entry.timesheetCode);
                if (existing != undefined)
                    existing.hours += parseFloat(entry.hours);
                else
                    timesheetCodes.push({ code: entry.timesheetCode, hours: parseFloat(entry.hours) });
            }
        });

        setTotalByTimesheetCode(timesheetCodes);

        // update the changes

        if(!loading)
            saveChanges();



    }, [mondayEntries, tuesdayEntries, wednesdayEntries, thursdayEntries, fridayEntries, weekendEntries])

    useEffect(() => {
        if (!loading)
            saveChanges();

    }, [expensesEntries, weekCommencing])

    const saveChanges = () => {
       

        console.log("submitting:", weekCommencing.toISOString().split('T')[0])


        axios
            .put(`https://${process.env.REACT_APP_IP}/api/timesheets/${params.id}`,
                {
                    WeekCommencing: weekCommencing.toISOString().split('T')[0],
                    Monday: mondayEntries,
                    Tuesday: tuesdayEntries,
                    Wednesday: wednesdayEntries,
                    Thursday: thursdayEntries,
                    Friday: fridayEntries,
                    Weekend: weekendEntries,
                    Expenses: expensesEntries
                },
                {
                    headers: { Authorization: `bearer ${user.token}` },
                }
                
            )
            .then((response) => {
                setRefresh(true);
            }).catch((error) => {
                toast.error(JSON.stringify(error.response.data.errors));
                    return Promise.reject(error)
                }
            )
    };

    function startOfWeek(date) {
        // Calculate the difference between the date's day of the month and its day of the week
        var diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);

        // Set the date to the start of the week by setting it to the calculated difference
        return new Date(date.setDate(diff));
    }

    


    

    return (
        <div>
            <SideContainer classes={"d-xl-none"}>

            </SideContainer>
            <MainContainer classes={"d-flex flex-column  p-2"}>
                <div className="row">
                    <div className="col-10">
                        <h4 className="my-2">Edit Timesheet - Week Commencing {weekCommencing.toLocaleDateString("en-GB")}</h4>
                    </div>
                    <div className="col-2">
                        <button
                            className="btn btn-primary "
                            onClick={() => navigate(-1)}>Back <FontAwesomeIcon icon={faArrowLeft} />{" "}
                        </button>
                    </div>
                </div>


                <form>
                    <div className="row">
                        <div className="col-10">
                            <input
                                type="date"
                                name="weekCommencing2"
                                placeholder="Week Commencing"
                                defaultValue={weekCommencing2}
                                onChange={handleChangeDate}
                            />
                        </div>
                       
                    </div>


                    


           

                



                    <TimesheetDay day="Monday" entries={mondayEntries} setEntries={setMondayEntries} timesheetCodes={timesheetCodes} date={weekCommencing.addDays(0)} />
                    <TimesheetDay day="Tuesday" entries={tuesdayEntries} setEntries={setTuesdayEntries} timesheetCodes={timesheetCodes} date={weekCommencing.addDays(1)} />
                    <TimesheetDay day="Wednesday" entries={wednesdayEntries} setEntries={setWednesdayEntries} timesheetCodes={timesheetCodes} date={weekCommencing.addDays(2)} />
                    <TimesheetDay day="Thursday" entries={thursdayEntries} setEntries={setThursdayEntries} timesheetCodes={timesheetCodes} date={weekCommencing.addDays(3)} />
                    <TimesheetDay day="Friday" entries={fridayEntries} setEntries={setFridayEntries} timesheetCodes={timesheetCodes} date={weekCommencing.addDays(4)} />
                    <TimesheetDay day="Saturday / Sunday" entries={weekendEntries} setEntries={setWeekendEntries} timesheetCodes={timesheetCodes} date={weekCommencing.addDays(5)} />
                    

                    <div className="row">
                        <div className="col-6"><p><strong>Weekly Target: {currentUser && currentUser.targetHours}</strong></p></div>
                        <div className="col-2 text-end"><p><strong>Total</strong></p></div>
                        <div className={currentUser && totalHours >= currentUser.targetHours ? "match col-1 text-end" : "mismatch col-1 text-end"}><p><strong>{totalHours} Hrs</strong></p></div>
                        <div className="col-1 text-end"><p><strong>{totalMiles} Miles</strong></p></div>
                        <div className="col-1 text-end"><p><strong>£{totalExpenses.toFixed(2)}</strong></p></div>
                        <div className="col-1"></div>
                    </div>
                    <div className="row">
                        <div className="col-7"><p><strong>TOIL: {hoursDifference} Hrs</strong></p></div>
                        <div className="col-3 text-end"><p><strong>Mileage @ £0.45p per mile</strong></p></div>
                        <div className="col-1 text-end"><p><strong>£{totalMileageExpenses.toFixed(2)}</strong></p></div>
                        <div className="col-1"></div>
                    </div>
                    <div className="row">
                        <div className="col-7"><p></p></div>
                        <div className="col-3 text-end"><p><strong>Total Expenses</strong></p></div>
                        <div className="col-1 text-end"><p><strong>£{totalExpenses.toFixed(2)}</strong></p></div>
                        <div className="col-1"></div>
                    </div>
                    <div className="row">
                        <div className="col-7"><p></p></div>
                        <div className="col-3 text-end"><p><strong>Total Mileage & Expenses</strong></p></div>
                        <div className="col-1 text-end"><p><strong>£{(totalMileageExpenses + totalExpenses).toFixed(2)}</strong></p></div>
                        <div className="col-1"></div>
                    </div>

                    <div className="row" style={{ marginTop: 20 }}>
                        <div className="col-12">
                            {/* Expenses Table */}
                            <h4>Expenses</h4>
                            <ExpensesTable entries={expensesEntries} setEntries={setExpensesEntries} nominalCodes={nominalCodes} projectCodes={timesheetCodes} departmentCodes={departmentCodes} costCodes={costCodes} expectedTotal={(totalExpenses + totalMileageExpenses).toFixed(2)} />
                        </div>
                    </div>

                    <div className="row" style={{ marginTop: 20 }}>
                        <div className="col-6">
                            <h4>Timesheet Codes</h4>

                            {/* Timesheet Code Summary */}
                            <div className="row">
                                <div className="col-6">Timesheet Code</div>
                                <div className="col-6">Hours</div>
                            </div>
                            {totalByTimesheetCode.map((entry, index) => (
                                <div key={index} className="row">
                                    <div className="col-6">{entry.code}</div>
                                    <div className="col-6">{entry.hours}</div>
                                </div>
                            ))}
                        </div>

                       
                        
                    </div>


                    


                   


                   
                </form>
                


            </MainContainer>
        </div>
    )
}
